import React from "react"
import { graphql } from "gatsby"

import Container from "../components/container"
import Metadata from "../components/metadata"
import ArticlePreview from "../components/list/article-preview"
import IconHeading from "../components/heading/icon-heading"

import ListIcon from "../components/images/heading-list.svg"

export default ({ data }) => (
  <Container>
    <Metadata
      title="All articles"
      description="Browse through all published articles on OkKotlin till now."
    />

    <h1>A new article, every Sunday</h1>
    <p>Browse through all articles published till now, every Sunday. Want to be notified? Subscribe to the
      <a href="/rss.xml" target="_blank" rel="noreferrer noopener nofollow"> feed</a>.</p>

    <IconHeading text="All articles" image={ListIcon} style={{ marginTop: 70 + "px", marginBottom: -15 + "px" }}/>

    {data.allMarkdownRemark.edges.map(({ node }) => (
      <div key={node.id}>
        <ArticlePreview post={node}/>
      </div>
    ))}
  </Container>
)

export const query = graphql`
query {
    site {
      siteMetadata {
        description
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 500
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            excerpt
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
